import { extendSx } from '@deltasierra/react/theme';

import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

type NavigationBarProps = {
    sx?: SxProps<Theme>;
};

export default function NavigationBar({ sx, ...props }: NavigationBarProps): JSX.Element {
    return (
        <Box
            {...props}
            component="span"
            sx={extendSx(
                {
                    backgroundColor: 'black',
                    display: 'block',
                    height: '2px',
                    width: '30px',
                },
                sx,
            )}
        />
    );
}

NavigationBar.displayName = 'NavigationBar';
