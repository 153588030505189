import { extendSx } from '@deltasierra/react/theme';

import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import Link from 'next/link';
import { customBreakpoints } from './Navigation';

type NavigationListAccountDropdownItemProps = {
    href: string;
    rel?: string;
    src: string;
    sx?: SxProps<Theme>;
    target?: string;
    text: string;
};

export default function NavigationListAccountDropdownItem({
    href,
    rel,
    src,
    sx,
    target,
    text,
    ...props
}: NavigationListAccountDropdownItemProps): JSX.Element {
    return (
        <Box {...props} component="li" sx={extendSx({ position: 'relative' }, sx)}>
            <Box
                component={target === '_blank' ? 'a' : Link}
                href={href}
                rel={rel}
                sx={(theme: Theme) => ({
                    '&:hover': {
                        backgroundColor: 'grey.100',
                    },
                    alignItems: 'center',
                    clear: 'both',
                    color: 'grey.900',
                    display: 'flex',
                    fontWeight: '400',
                    lineHeight: 1,
                    paddingBottom: '3px',
                    paddingLeft: '28px',
                    paddingRight: '28px',
                    paddingTop: '3px',
                    textDecoration: 'none',
                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    },
                    whiteSpace: 'nowrap',
                })}
                target={target}
            >
                <Box
                    component="i"
                    sx={(theme: Theme) => ({
                        backgroundImage: `url(${src})`,
                        backgroundPositionX: 'center',
                        backgroundPositionY: '2px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        display: 'flex',
                        height: '20px',
                        marginLeft: '5px',
                        marginRight: '5px',
                        opacity: 0,
                        [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                            opacity: 100,
                        },
                        width: '14px',
                    })}
                />
                {text}
            </Box>
        </Box>
    );
}

NavigationListAccountDropdownItem.displayName = 'NavigationListAccountDropdownItem';
