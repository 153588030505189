
import { Box } from '@mui/material';

export default function NavigationListAccountDropdownSeparator(): JSX.Element {
    return (
        <Box
            component="li"
            sx={{
                backgroundColor: 'grey.300',
                height: '1px',
                marginBottom: '9px',
                marginTop: '9px',
            }}
        />
    );
}

NavigationListAccountDropdownSeparator.displayName = 'NavigationListAccountDropdownSeparator';
